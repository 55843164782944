import { environment } from '../../environments/environment';
import isObject from 'lodash-es/isObject';

export function trackByIdFn(index, item) {
  if (!environment.production && !isObject(item) && item.id === undefined) {
    console.warn('trackById item provided does not have an id');
  }

  return item.id;
}

export function trackByDateFn(index, item) {
  if (!environment.production && !isObject(item) && item.date === undefined) {
    console.warn('trackById item provided does not have an id');
  }

  return item.date;
}

export function trackByDateControl(index, item) {
  const date = item.get('date')?.value;
  if (!environment.production && !isObject(item) && !date) {
    console.warn('trackByDateControl item provided does not have a date');
  }

  return date;
}

export function trackByLocationFn(index, item) {
  if (!environment.production && !isObject(item) && !isObject(item.location) && item.location.id === undefined) {
    console.warn('trackByLocation item provided does not have a item.location.id');
  }

  return item.location.id;
}

export function trackByOccurrenceIdFn(index, item) {
  if (!environment.production && !isObject(item) && item.occurrence_id === undefined) {
    console.warn('trackByOccurenceId item provided does not have a item.occurrence_id');
  }

  return item.occurrence_id;
}

export function trackByIndex(index: number): number {
  return index;
}

export function trackByValue(index: string, item: any): string {
  return item.value;
}
